import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'labelPluralDetection'})
export class LabelPluralDetectionPipe implements PipeTransform {

	transform(participantsLength: number, ...args): string {
		if (args.length === 3) {
			const participantsTotal = args[0];
			const maxItemInTheList = args[1];
			const word = args[2];

			if (participantsTotal <= 1) {
				return `${participantsLength} ${word}`;
			} else if (participantsTotal <= maxItemInTheList && participantsTotal > 1) {
				return `${participantsLength} ${word}s`;
			} else if (participantsTotal > maxItemInTheList) {
				return `${maxItemInTheList} / ${participantsTotal} ${word}s`;
			} else {
				return ``;
			}
		} else if (args.length === 1) {
			const word = args[0];

			if (participantsLength <= 1 ) {
				return `${participantsLength} ${word}`;
			} else {
				return `${participantsLength} ${word}s`;
			}
		} else {
			return ``;
		}
	}
}
