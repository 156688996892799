import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {Moment} from "moment";

@Pipe({name: 'beautyDateWithTime'})
export class BeautyDatePipeWithTime implements PipeTransform {
    transform(date: any, locale?: string): Moment | string {
        let beautyDate;
		if (!date) return '-';
        switch (locale) {
            case 'en':
                beautyDate = moment(date).locale('en').format('dddd DD MMMM YYYY HH:mm');
                break;
            default:
                beautyDate = moment(date).locale('fr').format('dddd DD MMMM YYYY à HH:mm');
        }

        if (beautyDate !== 'Invalid date') {
            return beautyDate;
        } else {
            return date;
        }
    }
}
