import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable} from 'rxjs';

import {environment} from 'src/environments/environment';
import {IUser} from "../types/user";

@Injectable({providedIn: 'root'})
export class UserService {

  constructor(private http: HttpClient) {
  }

    get(): Observable<IUser> {
        return this.http.get<IUser>(`${environment.api_url}/user`);
    }
}
