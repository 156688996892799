import { Component, Input, OnInit } from '@angular/core';
import { ToastTypeEnum } from '@enum/toast-type.enum';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html'
})
export class ToastComponent implements OnInit {

  static display: boolean = false;
  static type: ToastTypeEnum;
  static text: string;
  static isDisplaying: boolean = false;
  public toastTypeEnum = ToastTypeEnum;

  constructor() { }

  ngOnInit(): void {
  }

  public get display(): boolean {
    return ToastComponent.display;
  }

  public get text(): string {
    return ToastComponent.text;
  }

  public get type(): string {
    return ToastComponent.type;
  }

  static displayOnce(text: string, type: ToastTypeEnum): void {
    if(!ToastComponent.isDisplaying) {
      ToastComponent.isDisplaying = true;

      ToastComponent.text = text;
      ToastComponent.type = type;
  
      ToastComponent.display = true;
      setTimeout(() => {
        ToastComponent.display = false;
        ToastComponent.reset();
      }, 8000)
    }

  }

  static show(text: string, type: ToastTypeEnum): void {
    if(!ToastComponent.isDisplaying) {
      ToastComponent.isDisplaying = true;

      ToastComponent.text = text;
      ToastComponent.type = type;
      ToastComponent.display = true;
    }
  }

  static fade(text: string): void {
    setTimeout(() => {
      ToastComponent.text = text;
    }, 500)
    
    setTimeout(() => {
      ToastComponent.display = false;
      ToastComponent.reset();
    }, 2600)
  }

  static reset(): void {
    ToastComponent.text = null;
    ToastComponent.type = null;
    ToastComponent.isDisplaying = false;
  }
}
