import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentStatusEnum } from '@enum/document-status.enum';
import { IDocument } from '@interfaces/document';
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-file-box',
	templateUrl: './file-box.component.html',
})
export class FileBoxComponent {
	@Input() file: IDocument;
	@Input() isUpdatable: boolean = true;

	@Output() displayFile = new EventEmitter<string>();
	@Output() downloadFile = new EventEmitter<string>();
	@Output() deleteFile = new EventEmitter<string>();

	public documentStatusEnum = DocumentStatusEnum;

	constructor(public translateService: TranslateService) {
	}

	public isDisplayMenu(): boolean {
		return (
			this.isEnabledDisplay() ||
			this.isEnabledDownload() ||
			this.isEnabledDelete()
		);
	}

	public isEnabledDisplay(): boolean {
		return this.displayFile.observers.length > 0;
	}
	public isEnabledDownload(): boolean {
		return this.downloadFile.observers.length > 0;
	}
	public isEnabledDelete(): boolean {
		return this.deleteFile.observers.length > 0;
	}

	public emitDisplayFile(uuid: string): void {
		this.displayFile.emit(uuid);
	}
	public emitDownloadFile(uuid: string): void {
		this.downloadFile.emit(uuid);
	}
	public emitDeleteFile(uuid: string): void {
		this.deleteFile.emit(uuid);
	}
}
