import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToastComponent} from "@components/toast/toast.component";
import {ToastTypeEnum} from "@enum/toast-type.enum";
import {IAssembly} from "@interfaces/assembly";
import {IDocumentList} from "@interfaces/documentList";

@Component({
	selector: 'app-upload-document',
	templateUrl: './upload-document.component.html',
})
export class UploadDocumentComponent {

	@Input() assembly: IAssembly;
	@Input() acceptFileType: string = ".pdf, .odt, .doc, .docx";
	@Input() displayUploadBloc: boolean = false;
	@Output() documentList: EventEmitter<IDocumentList> = new EventEmitter<IDocumentList>();
	public files: any[] = [];
	public documents: IDocumentList = {documents: [], total: 0};

	public storeFileInBuffer(files: FileList): void {
		ToastComponent.show("Sauvegarde", ToastTypeEnum.SAVING);
		for (let i = 0; i < files.length; i++) {
			this.files.push(files.item(i));
		}
		this.emitUploadedDocuments();
	}

	public emitUploadedDocuments(): void {
		this.files.map(file => {
			this.documents.documents.push({
				name: file.name,
				extension: file.extension,
				key: file.key,
			});
			this.documents.total++;
		});
		this.documentList.emit(this.documents);
	}
}
