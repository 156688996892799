import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assemblyStatusColor'
})
export class AssemblyStatusColor implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case 'DRAFT':
        return 'offline';
      case 'IN_PROGRESS':
        return 'reminder';
      case 'COMPLETED':
        return 'success';
      case 'CANCELLED':
        return 'danger';
    }
    return '';
  }
}