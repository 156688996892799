import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { capModal } from "@interfaces/cap-modal";

@Component({
	selector: 'app-confirm',
	templateUrl: './confirm.component.html'
})
export class ConfirmComponent implements AfterViewInit {

	@ViewChild("confirmModal") confirmModal: ElementRef<capModal>;

	@Input('text') public text: string;
	@Input('title') public title = "Confirmation";
	@Input('confirmButton') public confirmButton = { label: 'Confirmer', variant: 'primary', icon: '' };
	@Input('cancelButton') public cancelButton = { label: 'Annuler', variant: 'tertiary', icon: '' };

	@Input() public openModal : boolean = false;
	@Output('onClickConfirm') public onClickConfirm = new EventEmitter<boolean>();
	@Output('onClickCancel') public onClickCancel = new EventEmitter<boolean>();

	public ngAfterViewInit(): void {
		if(this.openModal) {
			this.confirmModal.nativeElement.open();
		}
	}

	public clickCancel() {
		this.onClickCancel.emit(true);
		this.confirmModal.nativeElement.close();
	}

	public clickConfirm() {
		this.onClickConfirm.emit(true);
		this.confirmModal.nativeElement.close();
	}
}
