import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {

  @Input() error: Error;

  @Output() retry = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  public getTitle(): string {
    return this.error.stack;
  }

  public getSubtitle(): string {
    return this.error.name;
  }

  public getContent(): string {
    return this.error.message;
  }

  public emitRetry(): void {
    this.retry.emit();
  }

}
