import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-back",
  template: `
    <button variant="tertiary" color="dark-2">
      <cap-icon name="chevron-left" size="md"> </cap-icon>
      {{ "BACK" | translate }}
    </button>
  `,
})
export class BackComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
