import {NgModule} from "@angular/core";
import {PreloadAllModules, RouterModule, Routes} from "@angular/router";
import {AuthGuard} from "@modules/auth/guards/auth.guard";

const routes: Routes = [
    {
        path: "",
        loadChildren: () =>
            import("./pages/home/home.module").then((m) => m.HomePageModule),
    },
    {
        path: "assembly",
        loadChildren: () =>
            import("./pages/assembly/assembly.module").then((m) => m.AssemblyModule),
        canActivate: [AuthGuard],
    },
    {path: "**", redirectTo: "", pathMatch: "full"},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
