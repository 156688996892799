import { Pipe, PipeTransform } from '@angular/core';
import {IParticipant} from "../types/participant";

@Pipe({
    name: 'searchParticipant'
})
export class SearchParticipantPipe implements PipeTransform {

    transform(participants: IParticipant[], search: string = ''): IParticipant[] {
        search = search?.toLowerCase();
        return participants?.filter(p => {
            if (
                p.firstName.toLowerCase().includes(search) ||
                p.lastName.toLowerCase().includes(search) ||
                p.email.toLowerCase().includes(search)
            ) return true;
        })
    };

}
