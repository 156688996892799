import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'beautyDate'})
export class BeautyDatePipe implements PipeTransform {
    transform(date: any): any {
        const beautyDate = moment(date).locale('fr').format('LL');
        if (beautyDate !== 'Invalid date') {
            return beautyDate;
        } else {
            return date;
        }
    }
}