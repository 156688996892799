import {Injectable} from '@angular/core';
import {
    HttpEvent, HttpRequest, HttpHandler,
    HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ToastComponent} from "@components/toast/toast.component";
import {ToastTypeEnum} from "@enum/toast-type.enum";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    static errorMap = {
        [401]: 'MESSAGE.ERROR.401',
        [403]: 'MESSAGE.ERROR.403',
        [404]: 'MESSAGE.ERROR.404',
        [400]: 'MESSAGE.ERROR.400',
        [500]: 'MESSAGE.ERROR.DEFAULT',
        all_votes_no_completed: 'MESSAGE.ERROR.ALL_VOTES_NO_COMPLETED',
        assembly_not_in_progress: 'MESSAGE.ERROR.ASSEMBLY_NOT_IN_PROGRESS',
        vote_period_not_started: 'MESSAGE.ERROR.VOTE_PERIOD_NOT_STARTED',
        vote_period_done: 'MESSAGE.ERROR.VOTE_PERIOD_DONE',
        votes_already_validated: 'MESSAGE.ERROR.VOTE_ALREADY_VALIDATED',
        assembly_is_draft: 'MESSAGE.ERROR.ASSEMBLY_IS_DRAFT',
        unauthorized_participant: 'MESSAGE.ERROR.401',
        not_enough_credit: 'MESSAGE.ERROR.NOT_ENOUGH_CREDIT'
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                this.log(error);
                if (request.url.includes(environment.api_url)) ToastComponent.displayOnce(this.getErrorMessage(error), ToastTypeEnum.ERROR)
                return throwError(error);
            })
        );
    }

    private log(error: HttpErrorResponse) {
        if (!!environment.production) return;
        console.error(error);
    }

    private getErrorMessage(error: HttpErrorResponse): string {
        let errorKey = error.error?.errorName;
        if (errorKey === 'Error') errorKey = error.status;
        return ErrorInterceptor.errorMap[errorKey] ? ErrorInterceptor.errorMap[errorKey] : 'MESSAGE.ERROR.DEFAULT';
    }
}
