import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'langEmoji'})
export class LangEmojiPipe implements PipeTransform {

	transform(language: string): string {
		switch (language) {
			case 'fr':
				return '🇫🇷';
			case 'en':
				return '🇬🇧';
		}
	}
}
