import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'extensionToCssClass'
})
export class FileExtensionToCssClass implements PipeTransform {

    transform(extension: string): string {
        const prefix = 'fa-duotone fa-file'
        let suffix = ''
        switch (extension) {
            case 'pdf':
                suffix = '-pdf red-2'
                break;
            case 'odt':
                suffix = '-word primary-main'
                break;
            case 'doc':
                suffix = '-word primary-main'
                break;
            case 'docx':
                suffix = '-word primary-main'
                break;
            default:
                suffix = 'primary-main'
                break;
        }
        return `${prefix}${suffix}`
    }
}