import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { VoteEnum } from '@enum/vote.enum';
import { IParticipant } from '@interfaces/participant';
import { Subject } from 'rxjs';
import {IResolution} from "@interfaces/resolution";

@Component({
  selector: 'app-participant-vote',
  templateUrl: './participant-vote.component.html'
})
export class ParticipantVoteComponent implements OnInit {

  @Input() voters: IParticipant[];
  @Input() resolution: IResolution;
  @Input() modified: Subject<boolean> = new Subject<boolean>();
  @Output() participantVoteChoice = new EventEmitter<{value: string, participant: IParticipant}>();
  public voteEnum = VoteEnum;
  public voteAllSubject: Subject<boolean> = new Subject<boolean>();

  constructor() { }
  
  ngOnInit(): void {
    this.modified.subscribe({
      next: () => {
        this.voteAllSubject.next(false);
      }
    })
  }
  
  public vote(value: string, participant: IParticipant): void {
    this.participantVoteChoice.emit({value, participant});
    this.voteAllSubject.next(true);
  }

  public voteAll(value: string): void {
    this.voters.map(p => {
      if (p.votes.length > 0 && !p.voteValidation) {
        const vote = p.votes.find(vote => vote.resolution.uuid === this.resolution.uuid);
        if (vote) {
          vote.result = value;
          this.vote(value, p);
        }
      }
    });
    this.voteAllSubject.next(true);
  }

  authorizedVoter(voter: IParticipant) {
    return this.resolution.participants.map(p => { return p.uuid }).includes(voter.uuid);
  }
}

