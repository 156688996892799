import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { VoteEnum } from '@enum/vote.enum';
import { IParticipant } from '@interfaces/participant';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-toggle-vote',
  templateUrl: './toggle-vote.component.html'
})
export class ToggleVoteComponent implements OnInit {

  @Input() participant: IParticipant;
  @Input() resolutionUuid: string;
  @Input() modified: Subject<boolean> = new Subject<boolean>();
  @Output() voteChoice = new EventEmitter<string>();

  public voteEnum = VoteEnum;
  public initialState: string;
  

  constructor() { }

  ngOnInit(): void {
    this.autoCheck(true);
    this.modified.subscribe({
      next: (state: boolean) => {
        this.autoCheck(state);
      }
    })
  }

  private autoCheck(state: boolean) {
    if (state) {
      this.initialState = this.participant.votes.find(v => {
        return v.resolution.uuid === this.resolutionUuid;
      })?.result;
    } else {
      this.initialState = null;
    }
  }

  public vote(value: string): void {
    const vote = this.participant.votes.find(v => {
      return v.resolution.uuid === this.resolutionUuid;
    });
    vote.result = value;
    this.voteChoice.emit(value);
  }

}
