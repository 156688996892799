import { Injectable } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { IUser } from "@interfaces/user";
import { environment } from "../../../../environments/environment";
import { UserService } from "@services/user.service";
import { Auth0LockPasswordless } from 'auth0-lock';
import {ActivatedRoute, Router, UrlTree} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

@Injectable({ providedIn: "root" })
export class AuthService {
    public authResult$ = new BehaviorSubject<AuthResult>(null);
    public principal$ = new BehaviorSubject<IUser>(null);
    public isAuthenticated$ = new BehaviorSubject<boolean>(false);
    public auth0!: Auth0LockPasswordlessStatic | Auth0Client;
    public passwordlessOptions: Auth0LockPasswordlessConstructorOptions = {
        closable: false,
        mustAcceptTerms: true,
        allowedConnections: ['email'],
        passwordlessMethod: 'code',
        language: 'fr',
        languageDictionary: {
            title: ''
        },
        theme: {
            logo: 'https://cdn.axiocap.com/v1/AUTH_46ed50a8f09744768e21f93686e384db/capbloc-files-public/logo-sigle.png',
            primaryColor: 'rgb(75,151,213)',
            authButtons: {
                connexion: {
                    displayName: 'Connexion',
                    foregroundColor: 'rgb(75,151,213)',
                }
            }
        },
        auth: {
            redirect: true,
            redirectUrl: environment.auth0_redirect_url,
            responseType: 'token id_token',
            params: {
                scope: 'openid profile email',
            },
        }
    };

    constructor(private _userService: UserService,
                private _router: Router,
                private _snapshot: ActivatedRoute,
                private _translateService: TranslateService) {}

    public async identify(session): Promise<void> {
        this.authResult$.next(session);
        const user = await firstValueFrom(this._userService.get());
        this.principal$.next(user);
        this.isAuthenticated$.next(true);
        if (session.state) {
            const decodedState = atob(session.state);
            const url = decodedState.split('?')?.[0];
            const urlTree = this._router.parseUrl(decodedState);
            const queryParams = urlTree.queryParams?.assemblyUuid ? { assemblyUuid: urlTree.queryParams.assemblyUuid } : undefined;
            await this._router.navigate([url], { queryParams });
        } else {
            await this._router.navigate(['']);
        }
    }

    public async checkLogin(state: string) {
        if (!this.authResult$.getValue()) {
            try {
                await this.configureAuth0Client(state);
                const session = await this.checkSession();
                if (session) {
                    await this.identify(session);
                    return true;
                } else {
                    if (this.auth0 instanceof Auth0Client) {
                        const user = await firstValueFrom(await this._userService.get());
                        this.principal$.next(user);
                        this.isAuthenticated$.next(true);
                        return true;
                    }
                }
            } catch (err) {
                if (err?.code === 'login_required' && this.auth0 instanceof Auth0LockPasswordless) {
                    this.auth0.show();
                    if (this.checkEmailFromState(state)) this.blockEmail();
                } else if (err?.status === 401) {
                    this.logout();
                }
                return false;
            }
        } else {
            return true;
        }
    }

    private prefillEmail(state: string) {
        const email = this.checkEmailFromState(state);
        if (email !== undefined) {
            this.passwordlessOptions.prefill = { email: email };
        }
    }

    public async checkSession(): Promise<AuthResult | void> {
        if (this.auth0 instanceof Auth0Client) {
            return this.auth0.checkSession(); //CYPRESS
        } else {
            return new Promise((resolve, reject) => {
                this.auth0.checkSession({}, (err, res) => {
                    if (res) {
                        resolve(res);
                    }
                    if (err) {
                        reject(err);
                    }
                });
            });
        }
    }

    async configureAuth0Client(state: string = null): Promise<void> {
        const nonce = this.generateNonce();
        if ((window as any).Cypress) {
            this.auth0 = await createAuth0Client({
                domain: environment.auth0_domain,
                client_id: environment.auth0_client_id,
                redirect_uri: environment.auth0_redirect_url,
                audience: 'http://api.infogreffe.localhost/api/v1/',
                cacheLocation: (window as any).Cypress ? "localstorage" : "memory",
            });
        } else {
            await this.translateAuthConfig();
            if (state) this.passwordlessOptions.auth.params.state = state;
            this.prefillEmail(state);
            this.auth0 = new Auth0LockPasswordless(
                environment.auth0_client_id,
                environment.auth0_domain,
                { ...this.passwordlessOptions, auth: { ...this.passwordlessOptions.auth, params: { ...this.passwordlessOptions.auth.params, nonce: nonce } } }
            )}
    }

    private generateNonce(): string {
        const nonce = Math.random().toString(36).substring(2);
        localStorage.setItem('auth_nonce', nonce);
        return nonce;
    }

    public async logout() {
        localStorage.removeItem('auth_nonce');
        await this.auth0.logout({ returnTo: environment.auth0_redirect_url });
        this.isAuthenticated$.next(false);
    }

    async translateAuthConfig() {
        const availableLanguages = ['fr', 'en'];
        const language = availableLanguages.includes(this._translateService.getBrowserLang()) ? this._translateService.getBrowserLang() : 'en';
        this.passwordlessOptions.language = language;
        const translates = await firstValueFrom(this._translateService.getTranslation(language));
        this.passwordlessOptions.languageDictionary['signUpTerms'] = translates?.AUTH?.SIGNUP_TERMS;
        this.passwordlessOptions.theme.authButtons['connexion'].displayName = translates?.BUTTON?.LOGIN;
    }

    private blockEmail(): void {
        if (this.auth0 instanceof Auth0LockPasswordless) {
            this.auth0.on('socialOrEmail ready', event => {
                const input = document.getElementById('1-email');
                if (input) {
                    input.setAttribute('disabled', 'disabled');
                    input.style.cursor = 'not-allowed';
                }
            });
        }
    }

    private checkEmailFromState(state: string): string {
        const url = atob(state);
        const encodedEmail = url.split('?email=')?.[1]?.replace('%20', '+');
        return encodedEmail ? decodeURI(encodedEmail) : '';
    }
}