import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {AuthService} from "../../../auth/services/auth.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
})
export class NavbarComponent {

    public principal$ = this._authService.principal$;
    public langList$ = this._translateService.langs;
    public selectedLang = this._translateService.currentLang;

    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _translateService: TranslateService
    ) {
    }

    async onClickLogout() {
       await this._authService.logout();
    }

    onClickChangeLanguage(language: string) {
        this._translateService.use(language);
        this.selectedLang = this._translateService.currentLang;
    }
}
