import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {firstValueFrom, Subscription} from "rxjs";
import {Intercom} from "@supy-io/ngx-intercom";
import {AuthService} from "@modules/auth/services/auth.service";
import {environment} from "../environments/environment";

@Component({
  selector: "app-root",
  template: `
    <capbloc-design>
      <app-navbar></app-navbar>
      <div style="width: 100vw;">
        <app-toast
          auto-layout
          col="12"
          x-size="fill-container"
          direction="horizontal"
          align="center"
        ></app-toast>
        <router-outlet></router-outlet>
      </div>
    </capbloc-design>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  userSubscription: Subscription;
  constructor(public translate: TranslateService, private _titleService: Title, private _intercom: Intercom, private _authService: AuthService) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
    }

  async ngOnInit() {
    this.userSubscription = this._authService.principal$.subscribe((principal) => {
      if (principal) {
        this._intercom.boot({
          app_id: environment.intercom,
          name: principal.name,
          email: principal.email,
          user_id: principal.id.toString()
        });
      } else {
        this._intercom.boot({
          app_id: environment.intercom
        });
      }
    });
    this._titleService.setTitle(await firstValueFrom(this.translate.get('TITLE')));
    this._samsungBrowserCheck();
  }

  private _samsungBrowserCheck() {
    if (navigator.userAgent.match(/SamsungBrowser/i)) {
      document.documentElement.classList.add('samsung-browser');
    }
  }
}
