import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'participantStatusColor'
})
export class ParticipantStatusColorPipe implements PipeTransform {

  transform(value: string): string {

    switch (value) {
      case 'PRESENT':
        return 'success';
      case 'REPRESENTED':
        return 'update';
      case 'MISSING':
        return 'danger';
      case 'WAITING':
        return 'reminder';
      case 'SUMMON':
        return 'offline';
      default: return 'offline'
    }
  }
}
