import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDocument } from '@interfaces/document';
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-file-row',
	templateUrl: './file-row.component.html',
})
export class FileRowComponent {
	@Input() file: IDocument;
	@Input() isUpdatable: boolean = true;

	@Output() displayFile = new EventEmitter<string>();
	@Output() downloadFile = new EventEmitter<string>();
	@Output() deleteFile = new EventEmitter<string>()

	constructor(public translateService: TranslateService) {
	}

	public isDisplayMenu(): boolean {
		return (
			this.isEnabledDisplay() ||
			this.isEnabledDownload() ||
			this.isEnabledDelete()
		);
	}

	public isEnabledDisplay(): boolean {
		return this.displayFile.observers.length > 0 && this.file.extension === 'pdf';
	}
	public isEnabledDownload(): boolean {
		return this.downloadFile.observers.length > 0;
	}
	public isEnabledDelete(): boolean {
		return this.deleteFile.observers.length > 0;
	}

	public emitDisplayFile(uuid: string): void {
		this.displayFile.emit(uuid);
	}
	public emitDownloadFile(uuid: string): void {
		this.downloadFile.emit(uuid);
	}
	public emitDeleteFile(uuid: string): void {
		this.deleteFile.emit(uuid);
	}

	public extensionToCssClass(extension: string): string {
		const prefix = 'fa-duotone fa-file'
		let suffix = ''
		switch (extension) {
			case 'pdf':
				suffix = '-pdf red-2'
				break;
			case 'odt':
				suffix = '-word primary-main'
				break;
			case 'doc':
				suffix = '-word primary-main'
				break;
			case 'docx':
				suffix = '-word primary-main'
				break;
			default:
				suffix = 'primary-main'
				break;
		}
		return `${prefix}${suffix}`
	}
}
