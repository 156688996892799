import { BeautyDatePipe } from './beauty-date.pipe';
import { ParticipantStatusColorPipe } from './participant-status-color.pipe';
import { BeautyDatePipeWithTime } from './beauty-date-time.pipe';
import { AssemblyStatusColor } from './assembly-status-color.pipe';
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SearchParticipantPipe} from "./search-participant.pipe";
import {LabelPluralDetectionPipe} from "./label-plural-detection.pipe";
import {SafePipe} from "@pipes/safe.pipe";
import { LangEmojiPipe } from './lang-emoji.pipe';
import { FileExtensionToCssClass } from './file-extension-icon.pipe';

@NgModule({
    declarations: [
        SearchParticipantPipe,
        LabelPluralDetectionPipe,
        SafePipe,
        AssemblyStatusColor,
        BeautyDatePipeWithTime,
        ParticipantStatusColorPipe,
        BeautyDatePipe,
        LangEmojiPipe,
        FileExtensionToCssClass
    ],
    exports: [
        SearchParticipantPipe,
        LabelPluralDetectionPipe,
        SafePipe,
        AssemblyStatusColor,
        BeautyDatePipeWithTime,
        ParticipantStatusColorPipe,
        BeautyDatePipe,
        LangEmojiPipe,
        FileExtensionToCssClass
    ],
    imports: [CommonModule],
})
export class PipeModule {
}
