export const environment = {
    production: false,
    intercom: "saj7fczk",
    api_url: "https://api-preprod.axiocap.com/api/v1",
    auth0_domain: "auth-preprod.axiocap.com",
    auth0_client_id: "FpecPa9Y8pdjqCf1oujoHAZABZYAqf6e",
    auth0_redirect_url: "https://shareholder-preprod.axiocap.com/login"
};


