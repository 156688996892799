import { PipeModule } from '@pipes/pipe.module';
import { UploadDocumentComponent } from "./upload-document/upload-document.component";
import { FileRowComponent } from "./file-row/file-row.component";
import { FileBoxComponent } from "./file-box/file-box.component";
import { ErrorComponent } from "./error/error.component";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToastComponent } from "./toast/toast.component";

import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BackComponent } from "./back/back.component";
import {TranslateModule} from "@ngx-translate/core";
import { ConfirmComponent } from './comfirm/confirm.component';
import { ToggleVoteComponent } from './toggle-vote/toggle-vote.component';
import { ParticipantVoteComponent } from './participant-vote/participant-vote.component';

@NgModule({
  declarations: [
    ToastComponent,
    BackComponent,
    ErrorComponent,
    FileBoxComponent,
    FileRowComponent,
    UploadDocumentComponent,
    ConfirmComponent,
    ToggleVoteComponent,
    ParticipantVoteComponent,
  ],
    imports: [CommonModule, ReactiveFormsModule, FormsModule, PipeModule, TranslateModule],
  exports: [
    ToastComponent,
    BackComponent,
    ErrorComponent,
    FileBoxComponent,
    FileRowComponent,
    UploadDocumentComponent,
    ConfirmComponent,
    ToggleVoteComponent,
    ParticipantVoteComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule {}
