import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {environment} from 'src/environments/environment';
import {AuthService} from "../services/auth.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this._authService.authResult$)
            .pipe(switchMap(authResult => {
                    if (authResult?.accessToken && request.url.includes(environment.api_url)) {
                        const headers = request.headers
                            .set('Authorization', 'Bearer ' + authResult.accessToken);
                        const requestClone = request.clone({headers});
                        return next.handle(requestClone);
                    }

                    return next.handle(request);
                })
            );
    }
}

